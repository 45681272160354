/*-------- Custom Control -------*/

.custom-control {
	position: relative;
	display: block;
	min-height: 1.5rem;
	padding-left: 1.5rem;
}

.custom-control-inline {
	display: -ms-inline-flexbox;
	display: inline-flex;
	margin-right: 1rem;
}

.custom-control-input {
	position: absolute;
	z-index: -1;
	opacity: 0;
	&:checked~.custom-control-label::before {
		color: $white;
	}
	&:active~.custom-control-label::before {
		color: $white;
		background-color: #d4e1f4;
	}
	&:disabled~.custom-control-label {
		color: #868e96;
		&::before {
			background-color: #e0e5f3;
		}
	}
}

.custom-control-label {
	margin-bottom: 0;
	position: static !important;
	&::before {
		position: absolute;
		top: .25rem;
		left: 0;
		display: block;
		width: 1rem;
		height: 1rem;
		pointer-events: none;
		content: "";
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		background-color: #e0e5f3;
	}
	&::after {
		position: absolute;
		top: .25rem;
		left: 0;
		display: block;
		width: 1rem;
		height: 1rem;
		content: "";
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 50% 50%;
	}
}

.custom-radio {
	.custom-control-label::before {
		border-radius: 50%;
	}
	.custom-control-input {
		&:checked~.custom-control-label::after {
			background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
		}
		&:disabled:checked~.custom-control-label::before {
			background-color: rgba(0, 20, 142, 0.6);
		}
	}
}

.custom-select {
	display: inline-block;
	width: 100%;
	height: 2.375rem;
	padding: .5rem 1.75rem .5rem .75rem;
	line-height: 1.5;
	color: #a7b4c9;
	vertical-align: middle;
	background: $white url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 5'%3E%3Cpath fill='#999' d='M0 0L10 0L5 5L0 0'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
	background-size: 8px 10px;
	border: 1px solid #e0e5f3;
	border-radius: 3px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	&:focus {
		outline: 0;
		box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(25, 145, 235, 0.5);
		&::-ms-value {
			color: #3d4e67;
			background-color: $white;
		}
	}
	&[multiple],
	&[size]:not([size="1"]) {
		height: auto;
		padding-right: .75rem;
		background-image: none;
	}
	&:disabled {
		color: #868e96;
		background-color: #e0e5f3;
	}
	&::-ms-expand {
		opacity: 0;
	}
}

.custom-select-sm {
	height: calc(1.8125rem + 2px);
	padding-top: .5rem;
	padding-bottom: .5rem;
	font-size: 75%;
}

div.dataTables_wrapper div.dataTables_filter input {
	margin-left: .5em;
	display: inline-block;
	width: auto;
	height: calc(1.8125rem + 2px);
}

.custom-select-lg {
	height: calc(2.6875rem + 2px);
	padding-top: .5rem;
	padding-bottom: .5rem;
	font-size: 125%;
}

.custom-file {
	position: relative;
	display: inline-block;
	width: 100%;
	height: 2.375rem;
	margin-bottom: 0;
}

.custom-file-input {
	position: relative;
	z-index: 2;
	width: 100%;
	height: 2.375rem;
	margin: 0;
	opacity: 0;
	&:lang(en)~.custom-file-label::after {
		content: "Browse";
	}
}

.custom-file-label {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1;
	height: 2.375rem;
	padding: .375rem .75rem;
	line-height: 1.8;
	color: #3d4e67;
	background-color: $white;
	border: 1px solid #e0e5f3;
	border-radius: 3px;
}

.custom-control-label,
 ::-ms-backdrop {
	position: static;
}

.custom-file-label::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 3;
	display: block;
	height: calc(2.375rem - 1px * 2);
	padding: .375rem .75rem;
	line-height: 1.5;
	color: $white;
	content: "Browse";
	border-radius: 0 3px 3px 0;
}

.custom-control-input.is-valid~.custom-control-label,
.was-validated .custom-control-input:valid~.custom-control-label {
	color: $success;
}

.custom-control-input.is-valid~.custom-control-label::before,
.was-validated .custom-control-input:valid~.custom-control-label::before {
	background-color: #9eff3b;
}

.custom-control-input.is-valid~ {
	.valid-feedback,
	.valid-tooltip {
		display: block;
	}
}

.was-validated .custom-control-input:valid~ {
	.valid-feedback,
	.valid-tooltip {
		display: block;
	}
}

.custom-control-input.is-valid:checked~.custom-control-label::before,
.was-validated .custom-control-input:valid:checked~.custom-control-label::before {
	background-color: #78ed00;
}

.custom-control-input.is-valid:focus~.custom-control-label::before,
.was-validated .custom-control-input:valid:focus~.custom-control-label::before {
	box-shadow: 0 0 0 1px #f7f7fb, 0 0 0 2px rgba(94, 186, 0, 0.25);
}

.custom-control {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.custom-controls-stacked .custom-control {
	margin-bottom: .25rem;
}

.custom-control-label {
	vertical-align: middle;
	&:before {
		border: 1px solid rgba(167, 180, 201, 0.5);
		background-color: $white;
		background-size: .5rem;
	}
}

.custom-control-description {
	line-height: 1.5rem;
}


/*-------- Custom Control -------*/